import { css } from "styled-components";

export const shadows = {
  light: css`
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.075);
  `,
  normal: css`
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  `,
  heavy: css`
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.125);
  `,
};
